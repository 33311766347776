<template>
  <div class="ta-user">
    <display-data label="Name" :content="user.name"></display-data>
    <display-data
      :label="user.icPassportType"
      :content="user.icPassport"
    ></display-data>
    <div
      v-if="user.icPassportType === ID_TYPE.NRIC"
      :style="{
        display: 'grid',
        gap: '10px',
        gridTemplateColumns: '120px 1fr'
      }"
    >
      <display-data
        label="NRIC (Front)"
        :imgURL="$getFileURL(user.icFrontPhoto[0])"
        imgAlt="NRIC Front"
        :empty="_.isEmpty(user.icFrontPhoto[0])"
      ></display-data>
      <display-data
        label="NRIC (Back)"
        :imgURL="$getFileURL(user.icBackPhoto[0])"
        imgAlt="NRIC Back"
        :empty="_.isEmpty(user.icBackPhoto[0])"
      ></display-data>
    </div>
    <div v-else-if="user.icPassportType === ID_TYPE.PASSPORT">
      <display-data
        label="Passport"
        :imgURL="$getFileURL(user.passportPhoto[0])"
        imgAlt="Passport"
        :empty="_.isEmpty(user.passportPhoto[0])"
      ></display-data>
    </div>
    <display-data
      label="Nationality"
      :content="user.nationality"
    ></display-data>
    <display-data label="Contact" :content="user.contact"></display-data>
    <display-data label="Address" :content="user.address"></display-data>
    <display-data
      v-if="user.relationship"
      label="Relationship"
      :content="user.relationship"
    ></display-data>
  </div>
</template>

<script>
import { identificationType } from "@/enums";

export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData")
  },
  mixins: [],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      ID_TYPE: identificationType
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
